/*PC*/
@media screen and (min-width: 1026px) {
  .contents {
    width: 100%;
    height: 450px;
  }

  .contents:nth-child(even) {
    width: 100%;
    height: 450px;
    background-color: #b2d8e8;
  }

  .inner {
    width: 90%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .img {
    width: 600px;
    height: 375px;
    max-width: 100%;
    border-radius: 10px;
  }

  .description {
    width: 35%;
  }

  .title {
    font-size: 3em;
  }

  .text {
    font-size: 1.2em;
    text-align: left;
  }
}
/*タブレット*/
@media screen and (min-width: 482px) and (max-width: 1025px) {
  .contents {
    width: 100%;
  }

  .contents:nth-child(even) {
    width: 100%;
    background-color: #b2d8e8;
  }

  .inner {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .img {
    width: 320px;
    height: 220px;
    max-width: 100%;
    border-radius: 10px;
  }

  .description {
    width: 45%;
  }

  .title {
    font-size: 3em;
  }

  .text {
    font-size: 1.2em;
    text-align: left;
  }
}
/*スマホ*/
@media screen and (max-width: 481px) {
  .contents {
    width: 100%;
    height: 100vh;
  }

  .contents:nth-child(even) {
    width: 100%;
    height: 100vh;
    background-color: #5bbee5;
  }

  .inner {
    padding-top: 3%;
    width: 90%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
  }

  .img {
    width: 400px;
    height: 250px;
    max-width: 100%;
    border-radius: 10px;
  }

  .description {
    width: 100%;
  }

  .title {
    font-size: 3em;
  }

  .text {
    font-size: 1.2em;
    text-align: left;
  }
}
