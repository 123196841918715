.main_image {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../img/mv_bg01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  color: #fff;
  font-size: 3em;
  padding: 2%;
  border-radius: 15px;
}

.pic_down {
  position: absolute;
  bottom: calc(5vw + 5vh);
  animation-name: picdown;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  will-change: transform;
}

.pic_down::after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border-top: 5px solid #000;
  border-right: 5px solid #000;
  transform: rotate(135deg);
}

.logo {
  width: 600px; /* ロゴ画像サイズ */
}

@keyframes picdown {
  /*タイプライターライクなアニメーション*/
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}

@media screen and (max-width: 481px) {
  .logo {
    width: 300px; /* ロゴ画像サイズ */
  }
}