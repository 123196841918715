.App {
  text-align: center;
}

.form_h2 {
  color: #fff;
  background-color: #888;
}

.hidden {
  opacity: 0;
}

.opning {
  height: 100vh;
}

.slide {
  background-color: #b2d8e8;
}

/* ------------------------------------------------------------------------------------------------------------------------
-------------------------------------------------- module spacing ---------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------- */
.module--spacing--small {
  height: 20px;
}

.module--spacing--large {
  height: 40px;
}


